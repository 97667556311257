import React from "react";
import PadelRacketIcon from "../assets/images/padel_racket_icon.png";
import PadelCourtIcon from "../assets/images/padel_court_icon.png";
import PadelBallIcon from "../assets/images/padel_ball_icon.png";
import PadelRankingIcon from "../assets/images/point_ranking_icon.png";
import PadelWallsIcon from "../assets/images/padel_walls_icon.png";
import PadelServeIcon from "../assets/images/padel_serve_icon.png";
import PadelRulesIcon from "../assets/images/padel_rules_icon.png";

export default function AboutPadel() {
  return (
    <div>
      <section className="lead-section">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h2 style={{ fontSize: `30px` }}>Hva er padel?</h2>
        </div>
        <p style={{ margin: `1em 0`, fontSize: `20px` }}>
          Padel er en av verdens raskest voksende sporter, og er en racketsport
          som kan ses på som en blanding mellom tennis og squash. Padel er til
          eksempel Spania sin neste største sport, etter fotball. Det er lett å
          lære seg og veldig gøy å spille!
        </p>

        {/*Padel court image*/}
        <div className="fill">
          <img src={PadelCourtIcon} alt="En padelbane" />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 50,
            marginBottom: 25,
          }}
        >
          <h2 style={{ fontSize: `30px` }}>Ofte stilte spørsmål</h2>
        </div>

        <div style={{ textAlign: "left" }}>
          <p style={{ fontSize: `22px`, textDecoration: "underline" }}>
            Hvordan booker jeg?
          </p>
          <p style={{ fontSize: `18px` }}>
            Du booker via MATCHi. MATCHi kan lastes ned som app, eller du kan
            booke via deres nettside:{" "}
            <a
              href="https://www.matchi.se/facilities/padeluniverse"
              rel="noreferrer"
              target="_blank"
            >
              www.matchi.se
            </a>
            .
          </p>

          <p style={{ fontSize: `22px`, textDecoration: "underline" }}>
            Hvor mye koster det å booke en bane?
          </p>
          <p style={{ fontSize: `18px` }}>
            Det koster 330kr per time utenfor kjernetid, og 440kr per time
            innenfor kjernetid.
            <br></br>
            Vår kjernetid er mandag-fredag: 16-22 og lørdag-søndag: 09-22.
          </p>

          <p style={{ fontSize: `22px`, textDecoration: "underline" }}>
            Har dere utstyr?
          </p>
          <p style={{ fontSize: `18px` }}>
            Vi har racketer til leie (50kr) og ballrør som kan kjøpes (80kr). Du
            trenger kun treningstøy! Racketer og ballrør leies på senteret etter booking med Vipps.
          </p>

          <p style={{ fontSize: `22px`, textDecoration: "underline" }}>
            Hvor mange spiller sammen?
          </p>
          <p style={{ fontSize: `18px` }}>
            Vi har totalt 3 doble baner, der man spiller 2 mot 2 (4 spillere per bane).
          </p>

          <p style={{ fontSize: `22px`, textDecoration: "underline" }}>
            Hvilken bane skal jeg booke?
          </p>
          <p style={{ fontSize: `18px` }}>
            Alle banene er helt like, de har kun ulike navn på grunn av våre fantastiske samarbeidspartnere. Book den banen som passer best med din ønskede tid.
          </p>

          <p style={{ fontSize: `22px`, textDecoration: "underline" }}>
            Hvordan finner jeg andre å spille med?
          </p>
          <p style={{ fontSize: `18px` }}>
            Vi har en facebook-gruppe:{" "}
            <a
              href="https://www.facebook.com/groups/513549673046288"
              rel="noreferrer"
              target="_blank"
            >
              Padel Universe Grenland
            </a>{" "}
            , der man kan spørre etter andre å spille med. Vi har også ofte
            aktiviteter der man melder seg på og blir satt på lag med andre
            spillere. Dette finner du under aktiviteter i{" "}
            <a
              href="https://www.matchi.se/facilities/padeluniverse"
              rel="noreferrer"
              target="_blank"
            >
              MATCHi
            </a>{" "}
            (nederst på siden).
          </p>

          <p style={{ fontSize: `22px`, textDecoration: "underline" }}>
            Har dere ligaspill?
          </p>
          <p style={{ fontSize: `18px` }}>
            Vi har ligaspill på senteret vårt med mange lag og flere divisjoner.
            Her kan man melde på et lag (2 spillere eller mer) og spille
            organiserte kamper mot andre jevngode lag. Vi har divisjoner for
            nybegynnere og de mer erfarne. Ligaspillet kommer med få
            forpliktelser. Tidspunkt for når man spiller blir man enige om selv
            og det er få kamper som skal gjennomføres.
            <br />
            <br />
            Kontakt oss hvis du vil melde på et lag!
            <br />
            PS: Vi har også bedriftserie!
          </p>

          <p style={{ fontSize: `22px`, textDecoration: "underline" }}>
            Kan jeg spille fast hver uke?
          </p>
          <p style={{ fontSize: `18px` }}>
            Det kan du! Ta kontakt med oss, så blir vi enige om en avtale du er
            fornøyd med.
          </p>

          <p style={{ fontSize: `22px`, textDecoration: "underline" }}>
            Kan jeg leie hele senteret til en aktivitet, som for eksempel
            bursdag, avslutning, julebord eller lignende?
          </p>
          <p style={{ fontSize: `18px` }}>
            Det kan du! Ta kontakt med oss, så blir vi enige om en avtale du er
            fornøyd med.
          </p>

          <p style={{ fontSize: `22px`, textDecoration: "underline" }}>
            Har dere bedriftsavtaler?
          </p>
          <p style={{ fontSize: `18px` }}>
            Det har vi. Din bedrift kan få både synlighet på senteret, og et
            definert antall rabbarterte spilletimer. Vi har allerede flere
            bedrifter med slike avtaler, som er veldig fornøyde. Perfekt for
            teambuilding!
          </p>

          <p style={{ fontSize: `22px`, textDecoration: "underline" }}>
            Fortsatt noe du lurer på?
          </p>
          <p style={{ fontSize: `18px` }}>
            Send oss en mail på{" "}
            <a href="mailto:contact@padeluniverse.no">
              contact@padeluniverse.no
            </a>
            , eller ring <a href="tel:004791695807">+47 916 95 807</a>.
          </p>
        </div>
      </section>

      {/*Gray background*/}
      <section className={"body-section"}>
        <div className={"body-section-content"}>
          {/*Padel racket*/}

          <div
            data-sal={"slide-up"}
            class="row align-items-center icon-item mt-50"
          >
            <div class="col-md-6 text-center">
              <img src={PadelRacketIcon} height="180" alt="En padelracket" />
            </div>
            <div class="col-md-6">
              <h2 className="display-5" style={{ margin: 0 }}>
                Racket
              </h2>
              <hr className="my-2" />
              <p>
                En padelracket består ikke av strenger som i tennis og squash.
                Den er kortere og har hull på overflaten. Det faktum at det ikke
                er noen strenger betyr at ballen ikke treffer med den samme
                kraften, som gir spilleren mer kontroll og en enklere start på
                sporten.
              </p>
            </div>
          </div>

          {/*Padel ball*/}
          <div
            data-sal={"slide-up"}
            className="row align-items-center icon-item mt-50"
          >
            <div className="col-md-6 text-center icon-image">
              <img src={PadelBallIcon} height="130" alt="Padelball" />
            </div>
            <div className="col-md-6">
              <h2 className="display-5" style={{ margin: 0 }}>
                Ball
              </h2>
              <hr className="my-2" />
              <p>
                Ballen i padel er nesten helt lik som i tennis, bortsett fra at
                den er litt mykere og mindre. Hvis man spiller på amatørnivå,
                går det helt fint å bruke en tennisball også.
              </p>
            </div>
          </div>

          {/*Padel points*/}

          <div
            data-sal={"slide-up"}
            className="row align-items-center icon-item mt-50"
          >
            <div className="col-md-6 text-center icon-image">
              <img src={PadelRankingIcon} height="180" alt="Padelpoeng" />
            </div>
            <div className="col-md-6">
              <h2 className="display-5" style={{ margin: 0 }}>
                Poengsystem
              </h2>
              <hr className="my-2" />
              <p>
                Poengsystemet i padel er akkurat som tennis. Det spilles best av
                3 set og i hvert set gjelder det å vinne 6 game. I ett game
                teller man poeng på følgende måte: 0 / 15 / 30 / 40 / game. Hvis
                stillingen er 40-40 (deuce) må man vinne med to poeng for at
                gamet skal avgjøres.
                <br />
                <br />I ett set gjelder det å først vinne 6 games. Hvis
                stillingen derimot blir 5-5, spilles det til 7 game. Hvis
                stillingen blir 6-6, spilles det et avgjørende game, kalt
                tiebreak. Tiebreaks spilles frem til et av lagene har vunnet 7
                poeng, hvor man også må vinne med minst to poeng.
              </p>
            </div>
          </div>

          {/*Padel walls*/}

          <div
            data-sal={"slide-up"}
            className="row align-items-center icon-item mt-50"
          >
            <div className="col-md-6 text-center icon-image">
              <img src={PadelWallsIcon} height="200" alt="Padelvegger" />
            </div>
            <div className="col-md-6">
              <h2 className="display-5" style={{ margin: 0 }}>
                Veggene
              </h2>
              <hr className="my-2" />
              <p>
                Banen i padel består både av glassvegger og sidegjerder. Når man
                skal ta imot en ball, kan man la den stusse opp i veggen etter
                at den har stusset i eget gress. Ballen er da fortsatt levende.
                <br />
                <br />
                Man kan til og med bruke sin egen glassvegg for å slå tilbake
                ballen, men dette er ikke lov for sidegjerder. Hvis man slår
                ballen i eget sidegjerde, taper man poenget. Hvis man slår
                ballen direkte i en av motstanderen sine vegger, regnes ballen
                som ute.
              </p>
            </div>
          </div>

          {/*Padel serve*/}

          <div
            data-sal={"slide-up"}
            className="row align-items-center icon-item mt-50"
          >
            <div className="col-md-6 text-center icon-image">
              <img src={PadelServeIcon} height="160" alt="Padelserve" />
            </div>
            <div className="col-md-6">
              <h2 className="display-5" style={{ margin: 0 }}>
                Serve
              </h2>
              <hr className="my-2" />
              <p>
                Første serve starter fra høyre del av banen og må treffe den
                motsatte serveruten på andre banehalvdel. Det er kun tillatt med
                underarmsserve, det vil si at det ikke er lov å serve over
                hoftehøyde.
                <br />
                <br />
                Serven skal stusse i bakken før den kan spilles videre fra
                motspiller eller treffe veggen. Serven kan ikke treffe
                sidegjerdet, kun glassveggen. Man har to forsøk på serven. Hvis
                ballen treffer nettet før den korrekt treffer motsatt side, blir
                det omserve.
              </p>
            </div>
          </div>

          {/*Padel rules*/}

          <div
            data-sal={"slide-up"}
            className="row align-items-center icon-item mt-50"
          >
            <div className="col-md-6 text-center icon-image">
              <img src={PadelRulesIcon} height="180" alt="Padelregler" />
            </div>
            <div className="col-md-6">
              <h2 className="display-5" style={{ margin: 0 }}>
                Oppsummering av regler
              </h2>
              <hr className="my-2" />
              <ul>
                <li>
                  På en vanlig bane (10x20m) spiller man alltid dobbel (to mot
                  to).
                </li>
                <li>Poengtellingen er likt som i tennis.</li>
                <li>Linjene på banen brukes kun ved serve.</li>
                <li>
                  Serve skal slås underhånds og stusse i motstanderen sin
                  serverute.
                </li>
                <li>Man har to forsøk på serve.</li>
                <li>
                  Hvis ballen går direkte i motstanderen sin vegg, regnes ballen
                  som ute. Den må først stusse i gresset på motsatt side.
                </li>
                <li>Man kan ikke slå ballen i eget sidegjerde.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
