import * as React from "react";
import Footer from "./footer";
import TopJumbotron from "./top-jumbotron";
import NavBar from "./navbar";
import AboutPadel from "./about-padel";
import Partners from "./partners";

// markup
const IndexPage = () => {
  return (
    <main>
      <NavBar />
      {/*<TopTextNotification />*/}
      <title>Padel Universe</title>
      <TopJumbotron />
      {/*<Instagram />*/}
      {/*<ComingSoon />*/}
      <AboutPadel />
      <Partners />
      <Footer />
    </main>
  );
};

export default IndexPage;
